import { blog } from "../constants";
import styles from "../style";
// import FeedbackCard from "../components/FeedbackCard";
import BlogCard from "../components/BlogCard";
import { bannerImage1, imageBlogPrimary } from "../assets"
import Title from "../components/Title";
import "./../styles/components/Card4.scss"
// import "../../assets/styles/Mision.scss";



const BlogSection = () => (
  <section id="services" className={` `}>
    {/* <div className="absolute z-[0] w-[60%] h-[60%] -right-[50%] rounded-full blue__gradient bottom-40" /> */}

    <div className="">
      <h2 className="">BLOG</h2>
      <br className="" />{" "}
    </div>
    <div className="card4" >

      <div className=" " onClick={() => window.location.href = `/section`}>
        <img src={imageBlogPrimary} alt="Work Image" className="image" width={'100%'} />
        {/* <Title title="Finanzas vs Contabilidad" color="blue" /> */}


        <h2 className="">
        Finanzas vs Contabilidad
        </h2>
        <p>
          En este blog, exploraremos las diferencias fundamentales entre la contabilidad y las finanzas, y cómo cada una desempeña un papel único en la gestión financiera de una empresa.
        </p>

      </div>
    </div>
    <div className="blogs-container" id="blog1">
      <div className="container">
        <div className="blogs">
          {blog.map((card) => <BlogCard key={card.id} {...card} />)}
        </div>
      </div>
    </div>


  </section >
);

export default BlogSection;
