import { quotes } from "../assets";
import Button from "./Button";
import "./../styles/components/Card3.scss"

const BlogCard = ({ subtitle, name, title, img, id }) => (
  <div className="card" onClick={() => window.location.href = `/blogsection/${id}`}>
    <img src={img} alt={name} className="image" width={"100%"} />
    

    <div className="card-title">
      {title}
    </div>
    <div className="card-text">
    {subtitle}
    </div>
    



  </div>
);


export default BlogCard;
