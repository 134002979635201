import React from "react";
import "../styles/components/Button.scss";

export default function Button({ content, icon = "", color = "blue", message}) {
  const whatsappLink = `https://wa.me/593984361755?text=${encodeURIComponent(message)}`;
  const handleClick = () => {
    window.open(whatsappLink, '_blank'); // Abre el enlace en una nueva pestaña
  };
  return (
    <button className={`${color}`} onClick={handleClick} >
      {content} {icon}
    </button>
  );
}
