import React from 'react';
import Navbar from "../../components/Navbar";
import Objetivos from './Objetivos';
import Alcance from './Alcance'
import Banner from './Banner';
import Mision from './Mision';
import Perspectiva from './Perspectiva';
import Usuarios from './Usuarios';
import Experiencia from "./Experiencia";
import Nuestrosclientes from '../../sections/Nuestrosclientes';
import Clientesnuestros from '../../sections/Clientesnuestros';
import Footer from '../../sections/Footer';

const Blog = () => {
  return (
    <div>
      <div>
      <Mision/>
      <Footer/>
      </div>
    </div>
  )
}

export default Blog