import styles from '../../style';
import React from 'react'
import Footer from '../../sections/Footer';
import Navbar from "../../components/Navbar";
import { BlogSection } from '../../sections';
function Blog() {
  return (
    <div>
      <div className="mision-container" id="services" >
        <Navbar />
        <div className="container">
          <BlogSection/>

        </div>


      </div>
      <Footer />
    </div>
    // <div className="bg-primary w-full overflow-hidden">
    //   <div className={`${styles.paddingX} ${styles.flexCenter}`}>
    //     <div className={`${styles.boxWidth}`}>
    //       <Navbar />
    //     </div>
    //   </div>

    //   <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
    //     <div className={`${styles.boxWidth}`}>
    //       <BlogSection />
    //     </div>
    //   </div>

    //   <div className={`bg-blue-color ${styles.paddingX} ${styles.flexCenter}`}>
    //     <div className={`${styles.boxWidth}`}>
    //       <Footer />
    //     </div>
    //   </div>
    // </div>
  )
}

export default Blog