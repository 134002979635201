import React, { useState } from "react";
import "../styles/components/Form.scss";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function Form() {
  const botonStyle = {
    backgroundColor: "blue",
    color: "white",
    padding: "10px",
    borderRadius: "5px",
  };

  const [empresa, setEmpresa] = useState("");
  const [tipoEmpresa, setTipoEmpresa] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [numEmpleados, setNumEmpleados] = useState("");
  const [comentarios, setComentarios] = useState("");


  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    const formData = {
      empresa: empresa,
      tipo_empresa: tipoEmpresa,
      correo: correo,
      telefono: telefono,
      num_empleados: numEmpleados,
      comentario: comentarios
    };


    if (formData.empresa !== '' && formData.tipo_empresa !== '' && formData.correo !=='' && formData.telefono !== '' && formData.num_empleados !== '') {
      try {
        const response = await fetch(
          "https://script.google.com/macros/s/AKfycbztcEPRp6J67Uvsofe9aqkQSzhuEBwkIqqGdD_84NvTKfppVqu0HkKxFOU1q1uhgS9M/exec",
          {
            redirect: "follow",
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
              "Content-Type": "text/plain;charset=utf-8",
            },
          }
        );
        console.log("response: " + JSON.stringify(response));

        if (response.ok) {
          console.log("Submitted OK");
          setEmpresa("");
          setTipoEmpresa("");
          setCorreo("");
          setTelefono("");
          setNumEmpleados("");
          setComentarios("");
          window.alert("Formulario enviado con éxito");
        } else {
          console.error("Error send data");
        }
      } catch (error) {
        console.error("Error send data:", error);
      }
    } else {
      window.alert("Por favor llene todos los campos del formulario");
    }
    setLoading(false);
  };

  return (
    <div>
      <form className="form" onSubmit={handleSubmit}>
        <h4>Información Obligatoria</h4>
        <p>Llena esta información para poder asesorarte de mejor manera.</p>
        <div >
          <label htmlFor="nombre">Empresa:</label>
          <input
            type="text"
            id="empresa"
            name=""
            value={empresa}
            onChange={(e) => setEmpresa(e.target.value)}
            placeholder="Nombre de la empresa.."
          />
        </div>
        <div>
          <label htmlFor="">Tipo de Empresa:</label>
          <select
            id=""
            name=""
            value={tipoEmpresa}
            onChange={(e) => setTipoEmpresa(e.target.value)}
          >
            <option value="" disabled style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              Seleccione un tipo
            </option>
            <option value="Persona Natural">Persona Natural</option>
            <option value="Persona Natural Obligada a llevar Contabilidad">
              Persona Natural Obligada a llevar Contabilidad
            </option>
            <option value="Sociedad Anónima">Sociedad Anónima</option>
            <option value="Compañía LTDA.">Compañía LTDA.</option>
            <option value="S.A.S">S.A.S</option>
            <option value="Sociedad de Hecho">Sociedad de Hecho</option>
            <option value="Contribuyente Especial">
              Contribuyente Especial
            </option>
          </select>
        </div>

        <div>
          <label htmlFor="nombre">Correo:</label>
          <input
            type="email"
            id="empresa"
            name=""
            placeholder=""
            value={correo}
            onChange={(e) => setCorreo(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="nombre">Teléfono:</label>
          {/* <input type="text" id="empresa" name="" placeholder=""  value={telefono} onChange={(e) => setTelefono(e.target.value)}/> */}
          <PhoneInput
            defaultCountry="EC"
            placeholder="Ingrese..."
            value={telefono}
            onChange={setTelefono}
          />
        </div>

        <div >
          <label htmlFor="">Número de empleados:</label>
          <select
            id=""
            name=""
            value={numEmpleados}
            onChange={(e) => setNumEmpleados(e.target.value)}
          >
            <option value="" disabled style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              Seleccione una opción
            </option>
            <option value="1-10 empleados">1-10 empleados</option>
            <option value="11-20 empleados">11-20 empleados</option>
            <option value="21-50 empleados">21-50 empleados</option>
            <option value="más de 50 empleados">más de 50 empleados</option>
          </select>
        </div>

        <div>
          <label htmlFor="">Comentarios:</label>
          <textarea
            
            value={comentarios}
            onChange={(e) => setComentarios(e.target.value)}
            style={{ width: "96%" }}
            placeholder="Inserte un comentario..."
          />
        </div>

        <button style={botonStyle} type="summit" disabled={loading}>
          {loading ? "Enviando..." : "Enviar"}
        </button>
      </form>
    </div>
  );
}

export default Form;
