import React from "react";
import { useScroll } from "../../components/useScroll";
import { GoPlay } from "react-icons/go";
import { motion } from "framer-motion";
import Title from "../../components/Title";
import imageBlog from "../../assets/360blog.png";
import Navbar from "../../components/Navbar";

import "../../assets/styles/Mision.scss";

import {
    topContainerAnimation,
    videoAnimation,
    reasonsAnimation,
} from "../../utils/Animations";

function Mision() {
    const [element, controls] = useScroll()

    return (
        <div className="mision-container" id="services" >
            <Navbar />
            <div className="container">
                
                <img src={imageBlog} alt="Work Image" className="image" width={'100%'} />
                
                    <Title title="Finanzas vs Contabilidad" color="blue" />
                    <p>
                        La contabilidad y las finanzas son dos disciplinas fundamentales en el mundo empresarial, pero a menudo se confunden o se consideran intercambiables. Aunque ambas están estrechamente relacionadas y se complementan entre sí, existen diferencias clave que es importante comprender para tener un conocimiento más completo de estas áreas.
                    </p>

                    <p>
                        En este blog, exploraremos las diferencias fundamentales entre la contabilidad y las finanzas, y cómo cada una desempeña un papel único en la gestión financiera de una empresa.
                    </p>
                

                    <p >
                        La contabilidad se centra en el registro, clasificación y presentación de los datos financieros de una empresa. Su objetivo principal es proporcionar información precisa y confiable sobre las transacciones financieras y el desempeño económico de la empresa.
                    </p>
                    <p>
                        Por otro lado, las finanzas se centran en la gestión de los recursos financieros de la empresa, tomando decisiones estratégicas para maximizar el valor de la empresa y optimizar la asignación de capital.
                    </p>




                    <p>
                        La contabilidad se ocupa principalmente del registro de transacciones pasadas y presentes. Se enfoca en la preparación de estados financieros, como el balance general, el estado de resultados y el estado de flujo de efectivo.

                    </p>
                    <p>
                        Por otro lado, las finanzas se centran en el futuro y se preocupan por la planificación financiera, la evaluación de inversiones, la gestión de riesgos y la obtención de financiamiento.
                    </p>

                    <p>
                        La contabilidad se basa en datos históricos y se enfoca en el pasado y el presente. Su objetivo principal es proporcionar información precisa sobre el desempeño financiero pasado de una empresa. Por otro lado, las finanzas tienen una perspectiva más orientada hacia el futuro. Utilizan técnicas de valoración, proyecciones financieras y análisis de sensibilidad para evaluar el rendimiento y el potencial de crecimiento de una empresa.
                    </p>


                {/* <Title title="Usuarios" color="blue" style="text-align:left" /> */}


                    <p>
                        La contabilidad está dirigida a usuarios internos y externos de la empresa, como accionistas, inversionistas, proveedores, empleados y reguladores. Proporciona información financiera útil para la toma de decisiones, la rendición de cuentas y el cumplimiento de requisitos legales.

                    </p>
                    <p>
                        En contraste, las finanzas se centran en las necesidades de los administradores y ejecutivos de la empresa. Ayudan a tomar decisiones estratégicas sobre inversiones, financiamiento y gestión del capital de trabajo.
                    </p>


                    {/* <Title title="Enfoque analítico" color="blue" /> */}
                    <p>
                        La contabilidad se centra en la medición y el registro de los datos financieros utilizando principios y normas contables establecidos. Se adhiere a reglas y procedimientos específicos para garantizar la objetividad y la precisión de los informes financieros. Las finanzas, por otro lado, se basan en modelos y herramientas analíticas para evaluar el rendimiento financiero, realizar análisis de inversiones y gestionar el riesgo.
                    </p>

                <br />
                <br />

                    <Title title="Conclusión" color="blue" />

                    <p>
                        Tanto la contabilidad como las finanzas son fundamentales para comprender y administrar las actividades financieras de una empresa. Si bien la contabilidad se enfoca en el registro y la presentación precisa de los datos financieros, las finanzas se centran en la toma de decisiones estratégicas y la maximización
                    </p>

                <br />
                <br />


            </div>
        </div>

    );
}

export default Mision