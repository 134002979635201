const styles = {
  boxWidth: "xl:max-w-[1280px] w-full",

  heading2: "font-poppins xs:font-bold font-semibold xs:text-[40px] text-[30px]  xs:leading-[76.8px] leading-[55px] w-full",
  heading3: "font-poppins font-semibold xs:text-[40px] text-[30px]  xs:leading-[50px] leading-[40px] w-full",
  heading4: "font-poppins font-semibold xs:text-[20px] text-[15px]  xs:leading-[25px] leading-[33px] w-full",
  paragraph: "font-poppins font-normal  text-[18px] xs:leading-[30.8px] leading-[25px]",
  paragraph2: "font-poppins font-normal  text-[17px] ",
  paragraph3: "font-poppins font-normal  text-[18px] xs:leading-[30.8px] leading-[25px]",

  flexCenter: "flex justify-center items-center",
  flexStart: "flex justify-center items-start",
  flexEnd: "flex justify-center items-end",

  paddingX: "sm:px-32 px-6",
  paddingY: "sm:py-12 py-6",
  padding: "sm:px-24 px-6 sm:py-12 py-4",

  marginX: "sm:mx-16 mx-6",
  marginY: "sm:my-16 my-6",
};

export const layout = {
  // section: `flex md:flex-row flex-col ${styles.paddingY} ${styles.paddingX}`,
  section: `flex md:flex-row flex-col ${styles.paddingY}`,
  sectionReverse: `flex md:flex-row flex-col-reverse ${styles.paddingY}`,

  sectionImgReverse: `flex-1 flex ${styles.flexCenter} md:mr-10 mr-0 md:mt-0 mt-10 relative`,
  sectionImg: `flex-1 flex ${styles.flexCenter} md:ml-10 ml-0 md:mt-0 mt-10 relative`,

  sectionInfo: `flex-1 ${styles.flexStart} flex-col`,
};

export default styles;
