export const MenuItems = [
    {
        title: 'Asesoría',
        path: '/asesoria',
        cName: 'dropdown-link'
    },

    {
        title: 'Outsourcing',
        path: '/outsourcing1',
        cName: 'dropdown-link'
    },

    {
        title: 'Consultoría',
        path: '/consultoria',
        cName: 'dropdown-link'
    }
];
