import styles from '../../style';
import React from 'react'
import Footer from '../../sections/Footer';
import Navbar from "../../components/Navbar";
import Title from '../../components/Title';
import { useParams, Link } from 'react-router-dom';
import "../../assets/styles/Mision.scss";

import { blog } from '../../constants';


const Blog = (props) => {
  const { id } = useParams();
  const prevId = parseInt(id, 10) - 1;
  const postId = parseInt(id, 10) + 1;

  const buscarElementoPorId = (id) => {
    return blog.find(elemento => elemento.id === id);
  };

  const blogText = buscarElementoPorId(id);

  return (
    <div>
      <div className="mision-container">
        <div className=''>
          <div className=''>
            <Navbar />
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className=''>
          <div className=''>
            <section id="blog" className=''>
              <div className=''>
              <Title title={blogText.title} color="blue" />
                  
              </div>
              <br />
              <div className="flex flex-col sm:justify-center w-full  relative z-[1]">
                <div className={`${styles.paragraph}  mt-2 text-gray-color text-justify mb-8`} dangerouslySetInnerHTML={{ __html: blogText.content }}>
                </div>
                <br className="sm:block hidden" />
              </div>
              <div className="flex flex-row justify-between w-full  relative z-[1]">
                {prevId > 0 ? <Link to={`/blogsection/${prevId}`}><p className={`${styles.paragraph} text-gradient`}>Anterior</p></Link> : ''}
                {postId < 4 ? <Link to={`/blogsection/${postId}`}><p className={`${styles.paragraph} text-gradient`}>Siguiente</p></Link> : ''}
              </div>
              <br className="sm:block hidden" />
            </section >
          </div>
        </div>
      </div>


        <Footer />


    </div>

  )
}

export default Blog